<template>
  <el-dialog
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <template slot="title">
      <DialogTitleWithHint
        title="內部備註資訊"
        hint="此欄位為店家內部備註使用，不會顯示於電商商品頁面"
      />
    </template>
    <BaseElForm :model="formData">
      <BaseElFormItem label="備註">
        <BaseElInput
          v-model="formData.comment"
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 5 }"
        />
      </BaseElFormItem>
    </BaseElForm>

    <div slot="footer">
      <BaseElButton plain @click="cancel">否</BaseElButton>
      <BaseElButton type="primary" @click="confirm">是</BaseElButton>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'ShopCommentDialog',
  props: ['data'],
  data: () => ({
    formData: {
      comment: '',
    },
  }),
  mounted () {
    this.formData.comment = this.data.adminNote
  },
  methods: {
    confirm () {
      this.$emit('update', this.formData.comment)
      this.$emit('close')
    },

    cancel () {
      this.$emit('cancel')
      this.$emit('close')
    },
  },
}
</script>

<style lang="postcss" scoped>
::v-deep .el-dialog__body {
  @apply py-0;
}
</style>
