import { render, staticRenderFns } from "./ProductCreate.vue?vue&type=template&id=310501a6&scoped=true"
import script from "./ProductCreate.vue?vue&type=script&lang=js"
export * from "./ProductCreate.vue?vue&type=script&lang=js"
import style0 from "./ProductCreate.vue?vue&type=style&index=0&id=310501a6&prod&lang=scss"
import style1 from "./ProductCreate.vue?vue&type=style&index=1&id=310501a6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "310501a6",
  null
  
)

export default component.exports